<template>
  <Dialog header="Carga masiva de medicamentos" v-model:visible="modalVisible"
          :breakpoints="{'990px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
    <div>
      <div>
        <DataTable :value="instrucciones" class="p-datatable-sm text-sm">
          <template #header>
            <div class="flex justify-between my-3">
              <span class="font-bold text-base">Información de los campos del archivo Excel</span>
              <span class="p-input-icon-left">
                <Button label="Descargar plantilla" icon="pi pi-file-excel" @click="downLoadPlantilla"/>
             </span>
            </div>
          </template>
          <Column field="nombre" header="Nombre del campo" headerStyle="width: 15em"/>
          <Column field="condicion" header="Condición" headerStyle="width: 10em"/>
          <Column field="descripcion" header="Descripción" >
            <template #body="{data}"><pre>{{data.descripcion}}</pre></template>
          </Column>
        </DataTable>
      </div>
      <input type="file" ref="excelMasivo" class="hidden" @change="handleChangeExcel"/>
    </div>
    <template #footer>
      <Button label="Cargar medicamentos" icon="pi pi-cloud-upload" @click="loadMedicamentos"/>
    </template>
  </Dialog>
</template>

<script>
import { ref } from 'vue'
import { downloadPlantillaMasivo, loadPlantilla } from '../../services/cartaAgotado.service'
// import
import Swal from 'sweetalert2'

export default {
  emits: ['medicamentosLoaded'],
  setup (props, { emit }) {
    const instrucciones = ref([
      {
        nombre: 'Código Medicamento',
        condicion: 'Obligatorio',
        descripcion: 'Código de medicamento que desea agregar'
      },
      {
        nombre: 'Fecha Mx disponible',
        condicion: 'Opcional',
        descripcion: 'Fecha con formato de Año-Mes-Dia, en el cual estará \ndisponible el medicamento.\nSi está por confirmar se debe dejar vació'
      },
      {
        nombre: 'Estado',
        condicion: 'Opcional',
        descripcion: '1: Estado No Comercializado.\n2: Estado Descontinuado.\n3: Baja disponibilidad.\n4: Agotado.\nEn caso de tener mas de un estado por favor separarlos por "-".\nSi no tiene estado se debe dejar vació.\n'

      }
    ])
    const modalVisible = ref(false)
    const excelMasivo = ref()
    const laboratorioSelec = ref('')
    const openModal = (laboratorio) => {
      laboratorioSelec.value = laboratorio
      modalVisible.value = true
    }
    const downLoadPlantilla = () => {
      downloadPlantillaMasivo()
    }
    const loadMedicamentos = () => {
      excelMasivo.value.click()
    }
    const handleChangeExcel = () => {
      if (excelMasivo.value.files[0]) {
        const formData = new FormData()
        formData.append('file', excelMasivo.value.files[0])
        formData.append('laboratorio', laboratorioSelec.value)
        loadPlantilla(formData).then(({ data }) => {
          if (data.invalidos && data.invalidos.length) {
            let detalle = ''
            data.invalidos.map((x) => {
              detalle += `
                <tr>
                  <td align="left">${x.codMx}</td>
                  <td align="left">${x.linea}</td>
                </tr>`
            })
            const tabla = `
              <span class="text-sm">No se encontraron los siguientes medicamentos con el laboratorio seleccionado</span>
              <div style="max-height: 300px;overflow: auto;" class="text-sm">
                <table cellpadding="0" cellspacing="0" style="width: 100%" border>
                  <tr>
                    <th align="left">Código Mx</th>
                    <th align="left">Fila</th>
                  </tr>
                  ${detalle}
                </table>
              </div>
            `
            Swal.fire({
              icon: 'warning',
              title: 'Medicamentos no encontrados',
              html: tabla,
              showCancelButton: false
            }).then(() => {
              emit('medicamentosLoaded', data.validos)
              resetInput()
              modalVisible.value = false
            })
          } else {
            if (data.validos && data.validos.length) {
              emit('medicamentosLoaded', data.validos)
              resetInput()
              modalVisible.value = false
            }
          }
        }).catch((e) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: e.response.data.error
          })
          resetInput()
        })
      }
    }
    const resetInput = () => {
      const input = excelMasivo.value
      input.type = 'text'
      input.type = 'file'
    }
    return {
      modalVisible,
      laboratorioSelec,
      excelMasivo,
      instrucciones,
      openModal,
      downLoadPlantilla,
      handleChangeExcel,
      loadMedicamentos
    }
  }
}
</script>

<style scoped>

</style>
