<template>
  <Dialog header="Cartas Asociadas al medicamentos" v-model:visible="displayModal" :breakpoints="{'990px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
    <informesList />
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModal" autofocus />
    </template>
  </Dialog>
</template>

<script >
import { computed, defineAsyncComponent, ref } from 'vue'
import storeCartaAgotado from '../../store/store.cartaAgotado'
export default {
  name: 'CartasAsociadasDialog',
  components: {
    informesList: defineAsyncComponent(() => import('./tableList.vue'))
  },
  setup () {
    const displayModal = ref(false)
    const filters = computed(() => storeCartaAgotado.getters.getFilters)
    const toggle = (cartas) => {
      storeCartaAgotado.commit('setFilters', {
        fechaInicial: null,
        fechaFinal: null,
        fecha_carta: null,
        laboratorio: null,
        offset: 0,
        page: 1,
        limit: 5,
        cartas_ids: cartas
      })
      storeCartaAgotado.commit('setDisableRemove', true)
      storeCartaAgotado.dispatch('getPaginate', filters.value).then(({ data }) => {
        storeCartaAgotado.commit('setResponse', data.result)
        storeCartaAgotado.commit('setTotalRecords', data.count)
      })
      displayModal.value = !displayModal.value
    }
    const closeModal = () => {
      storeCartaAgotado.commit('setFilters', {
        fechaInicial: filters.value.fechaInicial,
        fechaFinal: filters.value.fechaFinal,
        fecha_carta: null,
        laboratorio: null,
        offset: 0,
        page: 1,
        limit: 10,
        cartas_ids: []
      })
      displayModal.value = !displayModal.value
    }
    return {
      displayModal,
      toggle,
      closeModal
    }
  }
}
</script>

<style scoped>

</style>
