<template>
  <div>
    <!-- <div class="flex justify-center">
      <span class="font-bold text-2xl">Crear carta</span>
    </div> -->
    <div class="flex justify-start bg-white p-2 header-title divheader">
      <div class="grid-cols-2 text-[#354357] font-bold my-auto p-1">
        <h2 class="text-lg">
          <i class="pi pi-file" style="font-size: 1rem"></i>
          Crear carta
        </h2>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-10 my-5">
      <div class="flex flex-col">
        <span class="font-bold">Fecha creación </span>
        <!-- <Calendar v-model="model.fecha_carta" dateFormat="dd/mm/yy"/> -->
        <InputText class="rounded w-full border" type="date" v-model="model.fecha_carta" :disabled="disableLaboratory"/>
        <MessageError :text="errors.fecha_carta"/>
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Fecha recepción</span>
        <!-- <Calendar v-model="model.fecha_recepcion" dateFormat="dd/mm/yy" :panelProps="{'width': '80vw !important'}"/> -->
        <InputText class="rounded w-full border" type="date" v-model="model.fecha_recepcion"/>
        <MessageError :text="errors.fecha_recepcion"/>
      </div>
      <!-- <div class="flex flex-col">
        <span class="font-bold">Fecha medicamento disponible </span>
        <Calendar v-model="model.fecha_mx_disponible"/>
      </div> -->
      <div class="flex flex-col">
        <span class="font-bold">Laboratorio</span>
        <Dropdown v-model="model.laboratorio"
                  :options="laboratoriosList"
                  optionLabel="laboratorio"
                  optionValue="laboratorio"
                  placeholder="Seleccione un laboratorio"
                  :filter="true" :showClear="true"
                  :filterPlaceholder="'Ingrese 4 o mas caracteres para buscar'"
                  :disabled="disableLaboratory"
                  @filter="getLaboratoriosList"
        />
        <MessageError :text="errors.laboratorio"/>
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Carta</span>
        <Button label="Cargar pdf"
                class="bg-blue-900"
                type="button"
                @click="openInput"
                v-if="model.pdfCarta === ''"
        />
        <div class="flex justify-between" v-else>
          <span class="text-muted mt-2">{{ model.pdfCarta }}</span>
          <Button label=""
                  icon="pi pi-times"
                  class="p-button-raised p-button-danger p-button-rounded"
                  type="button"
                  @click="deletePdfCarta"
          />
        </div>
        <MessageError :text="errors.pdfCarta"/>
      </div>
    </div>
    <div>
      <!-- <div class="flex justify-between my-3">
        <span class="font-bold text-xl">Listado medicamentos</span>
        <Button icon="pi pi-plus"
                class="bg-blue-900 p-button-rounded"
                @click="addLine"
        />
      </div> -->
      <DataTable :value="medicamentosList" responsiveLayout="scroll">
        <template #header>
          <div class="flex justify-between my-3">
            <span class="font-bold text-xl">Listado medicamentos</span>
            <span class="p-input-icon-left">
              <Button icon="pi pi-trash"
                      class="p-button-raised p-button-danger mr-3"
                      label="Limpiar"
                      @click="clearList"
              />
              <Button icon="pi pi-file-excel"
                      class="p-button-raised mr-3"
                      label="Masivo"
                      @click="openModalMassive"
              />
              <Button icon="pi pi-plus"
                      class="p-button-raised"
                      label="Agregar"
                      @click="addLine"
              />
             </span>
          </div>
        </template>
        <template #empty>
          No se han agregado medicamentos.
        </template>
        <Column field="CodMx" header="Cod. Mx" key="CodMx">
          <template #body="{data}">
            <div class="w-full flex justify-center" v-if="data.CodMx !== ''">
              <span>{{ data.CodMx }}</span>
            </div>
            <Dropdown v-model="data.CodMx"
                      v-else
                      :options="arrayMx"
                      optionLabel="nombre_medicamento"
                      optionValue="ItemCode"
                      placeholder="Seleccione un medicamento"
                      :filter="true"
                      :filterPlaceholder="'Ingrese 4 o mas caracteres para buscar'"
                      class="w-full"
                      @filter="handleSearch"
                      @change="handleChangeMx($event, data.id)"
            />
          </template>
        </Column>
        <Column field="NombreMx" header="Nombre medicamento" key="NombreMx"/>
        <Column field="cartas_existentes" header="Fecha mx disponible" key="cartas_existentes">
          <template #body="{data}">
            <div class="flex justify-center">
              <!-- <Calendar v-model="data.fecha_mx_disponible" dateFormat="dd/mm/yy" @date-select="validateDate($event,data.id)"/> -->
              <InputText
                class="rounded w-full border"
                type="date"
                v-model="data.fecha_mx_disponible"
                @blur="validateDate(data.fecha_mx_disponible,data.id)"
                :disabled="disabledInput(data.estado)"
              />
            </div>
          </template>
        </Column>
        <Column field="cartas_existentes" header="Cantidad cartas" key="cartas_existentes">
          <template #body="{data}">
            <div class="flex justify-center">
              <Button
                :label="data.cartas_existentes + ' cartas'"
                class="p-button-raised p-button-success"
                @click="showCartas(data.cartas_list)"
              />
            </div>
          </template>
        </Column>
        <Column field="estado" header="Estado" key="estado">
          <template #body="{data,index}">
            <div class="flex justify-center">
              <MultiSelect
                v-model="data.estado"
                :options="estadosList"
                :selectionLimit="3"
                :disabled="!['0000-00-00', null, ''].includes(data.fecha_mx_disponible) && data.estado.some(x => x.id !== 3)"
                @change="changeEstado($event, index)"
                optionLabel="nombre"
                class="w-full"
                placeholder="Seleccione un estado"/>
            </div>
          </template>
        </Column>
        <Column field="" header="Acciones" key="">
          <template #body="{data}">
            <div class="flex justify-center">
              <Button label=""
                      icon="pi pi-trash"
                      v-tooltip.top="'Eliminar'"
                      class="p-button-raised p-button-danger p-button-rounded"
                      @click="deleteLine(data.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Crear"
              class="bg-blue-900 mx-5"
              type="button"
              @click="onSubmit"
      />
    </div>
    <ModalDetalleCartaAsoc ref="modalCartasAsoc"/>
    <ModalCargaMasiva ref="modalCargaMasiva" @medicamentosLoaded="onLoadMedicamentos"/>
    <input type="file" ref="pdfCartaInput" class="hidden" accept="application/pdf" @change="handleChangePdf"/>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import * as yup from 'yup'
import { getLaboratorios, getMx, createCarta } from '../../services/cartaAgotado.service'
import ModalDetalleCartaAsoc from '../components/modalDetalleCartaAsoc.vue'
import ModalCargaMasiva from '../components/modalCargaMasiva.vue'
import storeCartaAgotado from '../../store/store.cartaAgotado'
import { useRouter } from 'vue-router'

const laboratoriosList = ref([])
const medicamentosList = ref([])
const estadosList = ref([
  { id: 1, nombre: 'No Comercializado' },
  { id: 2, nombre: 'Descontinuado' },
  { id: 3, nombre: 'Baja disponibilidad' },
  { id: 4, nombre: 'Agotado' }
])
const arrayMx = ref([])
const modalCartasAsoc = ref()
const modalCargaMasiva = ref()
const pdfCartaInput = ref()
const router = useRouter()
const filters = computed(() => storeCartaAgotado.getters.getFilters)
const disableLaboratory = ref(false)

const schema = yup.object({
  fecha_carta: yup.date().required('la fecha de creación es requerida').label(''),
  fecha_recepcion: yup.date().required('La fecha de recepción es requerido').label(''),
  // fecha_mx_disponible: yup.date().label(''),
  laboratorio: yup.string().nullable().required('El laboratorio es requerido').label(''),
  pdfCarta: yup.string().required('Se requiere el pdf de la carta').label('')
})

const { errors, values: model, handleSubmit } = useForm({
  validationSchema: schema
})

useField('fecha_carta')
useField('fecha_recepcion')
// useField('fecha_mx_disponible')
useField('laboratorio', null, { initialValue: '' })
useField('pdfCarta', null, { initialValue: '' })

const onSubmit = handleSubmit((values) => {
  const medicamentosVacios = medicamentosList.value.filter((x) => x.CodMx === '')
  if (medicamentosList.value.length === 0 || medicamentosVacios.length > 0) {
    Swal.fire({
      title: 'lista de medicamentos vaciá',
      text: 'Existen medicamentos agregados sin valor definido',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    })
    return
  }
  if (model.fecha_recepcion !== '0000-00-00') {
    const date = new Date()
    const fechaHoy = dayjs(date).format('YYYY-MM-DD')
    if (model.fecha_recepcion < model.fecha_carta) {
      Swal.fire({
        title: 'Advertencia',
        text: 'La fecha de recepción no puede ser menor a la fecha de la carta.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok'
      })
      return
    }
    if (model.fecha_recepcion > fechaHoy) {
      Swal.fire({
        title: 'Advertencia',
        text: 'La fecha de recepción no puede ser mayor a la fecha actual.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok'
      })
      return
    }
  }
  if (medicamentosList.value.some((x) => x.fecha_mx_disponible === '' && x.estado.length === 0)) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'Algunos medicamentos registrados no tienen fecha mx disponible ni estado, ¿Desea continuar con el proceso de creación?',
      confirmButtonText: 'Si',
      cancelButtonText: 'No, cancelar',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonColor: '#d33'
    }).then(({ value }) => {
      if (value) {
        generateCarta(values)
      }
    })
    return
  }
  generateCarta(values)
})
const resetFilters = () => {
  storeCartaAgotado.commit('setFilters', {
    fechaInicial: filters.value.fechaInicial,
    fechaFinal: filters.value.fechaFinal,
    fecha_carta: null,
    laboratorio: null,
    offset: 0,
    page: 1,
    limit: 10,
    cartas_ids: []
  })
  storeCartaAgotado.commit('setDisableRemove', false)
  router.push({ name: 'pharmasan.compras.carta-agotado' })
}
const getLaboratoriosList = ({ value }) => {
  const params = {
    search: value.toUpperCase()
  }
  if (value.length > 4) {
    getLaboratorios(params).then(({ data }) => {
      laboratoriosList.value = data
    })
  }
}
const addLine = () => {
  if (model.laboratorio && model.fecha_carta) {
    medicamentosList.value.push({
      id: medicamentosList.value.length,
      CodMx: '',
      NombreMx: '',
      cartas_existentes: 0,
      cartas_list: [],
      fecha_mx_disponible: '',
      estado: []
    })
  } else {
    Swal.fire({
      title: 'Advertencia',
      text: 'Seleccione un laboratorio e ingrese una fecha de carta.',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    })
  }
}
const openModalMassive = () => {
  if (!model.laboratorio) {
    Swal.fire({
      title: 'Advertencia',
      text: 'Seleccione un laboratorio.',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    })
    return
  }
  if (!model.fecha_carta) {
    Swal.fire({
      title: 'Advertencia',
      text: 'Seleccione la fecha de creación de la carta',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    })
    return
  }
  modalCargaMasiva.value.openModal(model.laboratorio)
}
const handleSearch = async ({ value }) => {
  if (value.length > 4) {
    if (model.laboratorio) {
      const params = {
        search: value.toUpperCase(),
        code_laboratorio: model.laboratorio
      }
      await getMx(params).then(({ data }) => {
        arrayMx.value = data
      })
    } else {
      await Swal.fire({
        title: 'Seleccione una opción',
        text: 'Seleccione un laboratorio',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ok'
      })
    }
  }
}

const handleChangeMx = ({ value }, id) => {
  const repetido = medicamentosList.value.filter((x) => x.CodMx === value)
  const index = medicamentosList.value.findIndex((a) => a.id === id)
  if (repetido.length > 1) {
    medicamentosList.value[index].CodMx = ''
    Swal.fire({
      title: 'Medicamento registrado con anterioridad',
      text: 'El medicamento que desea agregar ya se encuentra dentro del listado',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    })
    return
  }
  const data = arrayMx.value.find((a) => a.ItemCode === value)
  medicamentosList.value[index].CodMx = value
  medicamentosList.value[index].NombreMx = data.ItemName
  medicamentosList.value[index].cartas_existentes = data.cartas.length
  medicamentosList.value[index].cartas_list = data.cartas
}
const deleteLine = (id) => {
  const index = medicamentosList.value.findIndex((a) => a.id === id)
  medicamentosList.value.splice(index, 1)
}

const showCartas = (cartas) => {
  if (cartas.length) {
    modalCartasAsoc.value.toggle(cartas)
  }
}
const validateDate = (value, id) => {
  const index = medicamentosList.value.findIndex(x => x.id === id)
  if (value < model.fecha_carta) {
    medicamentosList.value[index].fecha_mx_disponible = ''
    Swal.fire({
      title: 'Advertencia',
      text: 'La fecha de disponibilidad no puede ser anterior a la fecha de la carta',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    })
  }
}

const changeEstado = ({ value }, index) => {
  /* no se puede seleccionar baja disponibilidad junto con otro estado */
  if (value.some(x => x.id === 3) && value.length > 1) {
    medicamentosList.value[index].estado = value.filter(x => x.id !== 3)
  }
  if (!['0000-00-00', null, ''].includes(medicamentosList.value[index].fecha_mx_disponible) && value.some(x => x.id !== 3)) {
    medicamentosList.value[index].estado = []
  }
}
const disabledInput = (estados) => {
  if (estados.some(x => x.id === 3)) return false
  return !!estados.length
}
const openInput = () => {
  pdfCartaInput.value.click()
}

const handleChangePdf = () => {
  if (pdfCartaInput.value.files[0]) {
    model.pdfCarta = pdfCartaInput.value.files[0].name
  }
}
const deletePdfCarta = () => {
  const input = pdfCartaInput.value
  input.type = 'text'
  input.type = 'file'
  model.pdfCarta = ''
}
const onLoadMedicamentos = (medicamentosData) => {
  const mdsRepetidos = []
  for (const md of medicamentosData) {
    const repetido = medicamentosList.value.some((x) => x.CodMx === md.ItemCode)
    if (!repetido) {
      const obj = {
        id: medicamentosList.value.length,
        CodMx: md.ItemCode,
        NombreMx: md.ItemName,
        cartas_existentes: md.cartas.length,
        cartas_list: md.cartas,
        fecha_mx_disponible: md.fecha_mx_disponible,
        estado: md.estado !== null ? md.estado.map((x) => {
          return estadosList.value.find((a) => a.id === parseInt(x.id))
        }) : []
      }
      medicamentosList.value.push(obj)
      validateDate(obj.fecha_mx_disponible, obj.id)
      continue
    }
    mdsRepetidos.push(md)
  }
  if (mdsRepetidos.length) {
    let detalle = ''
    mdsRepetidos.map((x) => {
      detalle += `
                <tr>
                  <td align="left">${x.ItemCode}</td>
                  <td align="left">${x.linea}</td>
                </tr>`
    })
    const tabla = `
      <span class="text-sm">Los medicamentos duplicados no se cargaron, por favor revisar</span>
      <div style="max-height: 300px;overflow: auto;" class="text-sm">
        <table cellpadding="0" cellspacing="0" style="width: 100%" border>
          <tr>
            <th align="left">Código Mx</th>
            <th align="left">Fila</th>
          </tr>
          ${detalle}
        </table>
      </div>
    `
    Swal.fire({
      icon: 'warning',
      title: 'Medicamentos duplicados',
      html: tabla,
      showCancelButton: false
    })
  }
}
const generateCarta = (values) => {
  const formData = new FormData()
  formData.append('fecha_carta', dayjs(values.fecha_carta).format('YYYY-MM-DD'))
  formData.append('fecha_recepcion', dayjs(values.fecha_recepcion).format('YYYY-MM-DD'))
  formData.append('laboratorio', values.laboratorio)
  formData.append('medicamentos', JSON.stringify(medicamentosList.value))
  formData.append('file', pdfCartaInput.value.files[0])

  createCarta(formData).then(({ data }) => {
    Swal.fire({
      title: 'Carta creada con éxito',
      html: `Se creó la carta No. <strong> ${data.id}</strong>`,
      icon: 'success',
      confirmButtonText: 'OK',
      showCancelButton: false,
      showConfirmButton: true,
      cancelButtonColor: '#d33'
    }).then(() => {
      resetFilters()
    })
  }).catch((e) => {
    Swal.fire({
      title: 'Error al crear la carta',
      text: e,
      icon: 'error',
      confirmButtonText: 'OK'
    })
  })
}

const clearList = () => {
  Swal.fire({
    icon: 'question',
    title: '¿Esta seguro?',
    text: '¿Desea limpiar el listado de medicamentos registros?',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si',
    cancelButtonText: 'No, cancelar',
    showCancelButton: true
  }).then(({ value }) => {
    if (value) {
      medicamentosList.value = []
    }
  })
}
watch(medicamentosList.value, (model) => {
  if (medicamentosList.value.length > 0 && model.laboratorio !== '') {
    disableLaboratory.value = true
  } else {
    disableLaboratory.value = false
  }
})
</script>

<style scoped>
::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

.header-title {
  background-color: #1c3faa;
  justify-content: space-between;
  color: white;
  border-radius: 5px;
}

.btn-create {
  color: #1C3FAA;
  background: #fff;
}
</style>
